<template>
  <!-- <div class="app-container home">欢迎登录DLVD后台管理系统！</div> -->
	<el-container v-loading="loading">
		<el-header><span class="noticeHeader">{{$t('homePage.homeTitle')}}</span></el-header>
		<el-main>
			<el-card v-for="(item,index) in noticeList" :key="index" shadow="always" class="ql-container ql-snow noticeCard" style="border: none;">
				<h1 class="ql-editor" style="text-align: center; width: 100%;padding: 0px;" v-html="item.noticeTitle"></h1>
				<span style="width: 100%;height: 30px;line-height: 30px;text-align: center;display: inline-block;font-size: 16px;color: #7e7c7a;">{{$t('homePage.homeUpdateTime') + ':' + item.updateTime}}</span>
				<el-main class="ql-editor" >
					<div @click="preViewImg" v-html="item.noticeContent">
						
					</div>
				</el-main>
			</el-card>
			<span v-if="noticeList.length == 0" style="display: inline-block; width: 100%;text-align: center;color: gray;font-size: 24px;margin-top: 20px;">{{$t('tipsInfo.noData')}}</span>
		</el-main>
		<el-image-viewer v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		<el-dialog
		  :title="$t('homePage.homeTitle')"
			:visible.sync="popup"
			:close-on-click-modal="false"
			width="620px"
			:append-to-body="true"
			@close="closeDialog"
		>
		  <el-row class="ql-container ql-snow" style="border: none; width: 100%;">
				<h1 class="ql-editor" style="text-align: center; width: 100%;padding: 0px;" v-html="dialogNoticeInfo.noticeTitle"></h1>
				<span style="width: 100%;height: 30px;line-height: 30px;text-align: center;display: inline-block;font-size: 16px;color: #7e7c7a;">{{$t('homePage.homeUpdateTime') + ':' + dialogNoticeInfo.updateTime}}</span>
				<el-main style="margin: 20px 0px;" class="ql-editor" >
					<div @click="preViewImg" v-html="dialogNoticeInfo.noticeContent">
					</div>
				</el-main>
			</el-row>
			<el-divider></el-divider>
			<el-footer style="height: 50px;">
				<el-button
				  type="primary"
					:disabled="countingTime != 0"
				  @click="popup = false"
					style="float: right;"
				>{{countingTime != 0?countingTips:$t('commonInfo.dialogBtnClose')}}</el-button>
			</el-footer>
		</el-dialog>
	</el-container>
</template>

<script>
	import "quill/dist/quill.core.css";//富文本样式
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: "home",
	components:{ElImageViewer},
  data() {
    return {
			noticeList:[],
			// 遮罩层
			loading:false,
			//搜索通告条件
			queryParams:{
				pageNum: 1,
				pageSize: 10,
				examineFlag:"0",//默认显示未审核的数据
			},
			showImg:false,
			imgList:[],
			
			// 弹窗公告
			popup:false,
			dialogNoticeInfo:{},
			
			timer:null,
			countingTime:5,
			countingTips:'5 S'
    };
  },
	created() {
		this.getList();
		
	},
  methods: {
		/** 查询公告列表 */
		
		async getList() {
			this.loading = true;
			let nowTime = new Date();
			let that = this;
			const res = await this.$http.get("/toborder/system/notice/list", {//线上需要加上/api
			  params: this.queryParams
			});
			if(res.code == 200){
				if(res.rows.length > 0){
					res.rows.forEach(item => {//筛选有效公告
						 let dataTime = new Date(item.validityTime);
						 if(dataTime > nowTime){
							 that.noticeList.push(item);
						 }
					});
					if(that.noticeList.length == 0){
						that.noticeList = [];
					}else{
						//没过期的公告获取第一条，如果为近两个月发布的公告，则显示弹窗信息 注：需要后台返回已阅读标记
						// let date = new Date();//当天
						// let beforeTime = new Date(date.getTime() - 24*60*60*1000*30*2);//两个月前时间
						// let firstDataTime = new Date(that.noticeList[0].updateTime);
						// if(firstDataTime > beforeTime){
						// 	that.popup = true;
						// 	that.dialogNoticeInfo = that.noticeList[0];
						// 	that.startToCount();
						// }
					}
				}else{
					that.noticeList = [];
				}
			}
			this.loading = false;
		},
		preViewImg(e){
			if(e.target.nodeName == 'IMG'){
				this.showImg = true;
				this.imgList = [];
				this.imgList.push(e.target.getAttribute('src'));
			}
		},
		//关闭图片
		closeImg(){
			this.showImg = false;
		},
		// 阅读公告倒计时
		startToCount(){
			let that = this;
			this.timer = setInterval(() => {
				that.countingTime --;
				that.countingTips = that.countingTime + ' S';
				if(that.countingTime == 0){
					clearInterval(that.timer);
					that.timer = null;  
				}
			},1000)
		},
		//关闭窗口
		closeDialog(){
			this.popup = false;
		},
	}
};
</script>

<style scoped>
.home {
  display: flex;
  line-height: 300px;
  font-size: 38px;
  justify-content: center;
  color: red;
}
.noticeHeader{
	width: 100%;
	height: 60px;
	line-height: 60px;
	text-align: center;
	display: inline-block;
	font-size: 24px;
	margin: 10px auto;
}
.noticeCard{
	width: 740px;	
	margin: 20px auto;
}
.noticeCard h1{
	font-size: 22px;
}
.noticeCard main{
	font-size: 14px;
}
</style>

